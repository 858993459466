import { PlanWidgetRole } from '../../constants/elements';
import model from './model';

export default model.createController(({ $w, $widget, flowAPI }) => {
  const setText = (text: string) => {
    $w(`#${PlanWidgetRole.RibbonText}`).text = text;
  };

  $widget.onPropsChanged((oldProps, newProps) => {
    setText(newProps.text);
  });

  return {
    pageReady: async () => {
      setText($widget.props.text);
    },
    exports: {
      getTextElement: () => {
        return $w(`#${PlanWidgetRole.RibbonText}`);
      },
    },
  };
});
